import React, { useState, Fragment } from 'react';
import Img from 'gatsby-image';
import { Divider, Row, Col, Grid } from '@components/Grid';
import Button from '@components/Button';
import { Link } from 'gatsby';

import styles from './styles.module.scss';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import SVG from 'react-inlinesvg';

const SiteLayoutOverview = ({
    siteScreenshot,
    iconHome,
    iconSearch,
    iconBooking,
    iconEnquiry,
    iconOffer,
    iconBookRequest,
    iconConversation,
    iconCalendar,
    iconApp,
    iconAdminPanel,
}) => {
    const [selectedTab, setSelectedTab] = useState('Home');
    const [selectedIcon, setSelectedIcon] = useState(iconHome);

    const tabsList = [
        { name: 'Home', icon: iconHome },
        { name: 'Search', icon: iconSearch },
        { name: 'Booking', icon: iconBooking },
        { name: 'Enquiry', icon: iconEnquiry },
        { name: 'Special offer', icon: iconOffer },
        { name: 'Request to book', icon: iconBookRequest },
        { name: 'Conversation', icon: iconConversation },
        { name: 'Calendar', icon: iconCalendar },
        { name: 'App', icon: iconApp },
        { name: 'Admin panel', icon: iconAdminPanel },
    ];

    return (
        <section className={styles.siteLayoutOverviewWrapper}>
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.tabsGridWrapper}>
                <Grid>
                    <div className={styles.verticalTabsWrapper}>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={4}>
                                <ul className={styles.tabsList}>
                                    {tabsList?.map((tab) => (
                                        <li
                                            className={`${
                                                tab?.name === selectedTab ? styles.selectedTab : ''
                                            }`}
                                            key={tab?.name}
                                            onClick={() => {
                                                setSelectedTab(tab?.name);
                                                setSelectedIcon(tab?.icon);
                                            }}
                                        >
                                            <img src={tab?.icon} alt={tab?.icon} />
                                            <h3>{tab?.name}</h3>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={12} sm={12} md={7} lg={8}>
                                <div className={styles.mainContent}>
                                    <div className={styles.textContent}>
                                        <div>
                                            <img
                                                src={selectedIcon}
                                                alt={`${selectedTab} icon`}
                                            />
                                            <h3>{selectedTab}</h3>
                                        </div>
                                        {selectedTab === 'Home' && (
                                            <p>
                                                The home page is where we reflect our true branding
                                                and show off amazing countries and boats to give our
                                                consumers a taste for what they could be seeing and
                                                doing. Using our custom designed hero graphic of a
                                                mega yacht sailing the seas against some bumpy
                                                waves, the graphic clearly resonates with the
                                                platform - search for your boat and set sail. We
                                                built specific data endpoints for searching boats by
                                                country, place or by random. These sections are rows
                                                on the homepage to allow users to easily skim
                                                through boats and navigate via countries without
                                                having to search for a location if they haven’t a
                                                specific place in mind. This kind of a hive-like
                                                system allows us to push users into finding boats
                                                without necessarily knowing what they want yet.
                                            </p>
                                        )}

                                        {selectedTab === 'Search' && (
                                            <Fragment>
                                                <p>
                                                    The search page is one of the biggest systems in
                                                    the website, this smart system combines
                                                    searching, results, filtering, maps, map markers
                                                    and search view types into one page for the
                                                    ultimate user experience. The most important
                                                    thing we pride the search system on which we try
                                                    to persist across our website is reusable URL’s.
                                                    A destination or specific map coordinates can be
                                                    passed through the URL and is consistently
                                                    updated as the user’s search changed or the user
                                                    drags the map, performs a filter or page change,
                                                    this ensures if the end user was to bookmark or
                                                    copy the link to share the exact same result set
                                                    would be returned as expected (given a
                                                    reasonable time frame).
                                                    <br />
                                                    The search filtering system allows a consumer to
                                                    filter boats on a huge level of items, such as
                                                    boat length, type, price, age, activities,
                                                    instant bookable and more.
                                                </p>

                                                <p>
                                                    We promote certain boats that are ranked as
                                                    premium boat listings, this may appeal more to
                                                    consumers as they are given premium status and
                                                    stand out more in the search system, this is an
                                                    integral part of the system that works out how
                                                    to rank them higher and manages to always manage
                                                    premium boat listings return within the top half
                                                    of the results so as to always be noticed
                                                    easily.
                                                    <br />
                                                    The search view types allow a user to search by
                                                    list and map, list only or map only, this is a
                                                    great way to change how a user would like to
                                                    search, list to see more detailed results at
                                                    once, map to more easily pan the world into
                                                    specific harbours that the user may be
                                                    interested in going to.
                                                    <br />
                                                    The map system works retrieves the exact
                                                    coordinates for every boat and places a marker
                                                    with the daily price on a physical map so users
                                                    can clearly see exactly where the boats in the
                                                    corresponding list are from. Users can drag the
                                                    map to replenish the list with new boats, or
                                                    zoom out for more of a broader search, or zoom
                                                    in for a more specific search.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Booking' && (
                                            <Fragment>
                                                <p>
                                                    Without bookings, no platform like this would
                                                    work! Our booking system is flawless and enables
                                                    users to book without human intervention by the
                                                    company at all, users simply find a boat they
                                                    would like, and then decide whether to enquire
                                                    with the host, or request to book (if non
                                                    instant-bookable) or book (if instant bookable).
                                                    The whole concept of these 3 different pathways
                                                    to connect a booking can be quite complicated,
                                                    and we spent a long time getting this right the
                                                    first time round. All the four methods to
                                                    formulating a booking will then forward the user
                                                    immediately to the conversation system.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Enquiry' && (
                                            <Fragment>
                                                <p>
                                                    An enquiry allows a user to connect to a host,
                                                    by using the conversation system, before a
                                                    conversation is struck the user must offer some
                                                    basic information, specifically a checkin and
                                                    checkout date, amount guests and an
                                                    introduction. The enquiry process can then turn
                                                    into a booking or a special offer if the host
                                                    presents one of these options within 24 hours,
                                                    otherwise it might be declined or become
                                                    expired, in which the user will have to find
                                                    another boat or attempt to enquire again if the
                                                    host is now interested.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Special offer' && (
                                            <Fragment>
                                                <p>
                                                    A special offer is made when an enquiry for a
                                                    certain price, guests or checkin or checkout has
                                                    been modified by the host and sent back to the
                                                    guest, in this 24 hour period the user can then
                                                    book for these specific guidelines if they so
                                                    choose.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Request to book' && (
                                            <Fragment>
                                                <p>
                                                    Request to book allows a user to provide upfront
                                                    details, same as an enquiry, along with payment
                                                    details which won’t be charged to the user
                                                    unless the host accepts the request within 24
                                                    hours.
                                                    <br />
                                                    Booking allows a user to provide upfront
                                                    details, same as an enquiry, along with payment
                                                    details which will be charged immediately.
                                                </p>
                                            </Fragment>
                                        )}
                                        {selectedTab === 'Conversation' && (
                                            <Fragment>
                                                <p>
                                                    Being able to converse from guest to host is
                                                    extremely important, hosts may want to know or
                                                    vet the guests before they accept them, and
                                                    guests may want to gather some more information
                                                    that may not be clear to them, a conversation
                                                    system is favourable as this allows them to stay
                                                    within the platform and creates ease of use when
                                                    the booking system is connected through the
                                                    conversation system so they can always track
                                                    their bookings through conversations and
                                                    vice-versa.
                                                </p>
                                                <p>
                                                    A conversation outlines the boat, the state of
                                                    the conversation and pricing of the given boat
                                                    for the period asked for, this is persisted to
                                                    both end users so it’s clear to both parties
                                                    exactly what the conversation is about
                                                    <br />
                                                    We worked a lot on web sockets and notifications
                                                    to ensure conversations updated other users
                                                    notification count, along with text messages,
                                                    emails and a special “sending message” graphic
                                                    when a user is typing for an enhanced user
                                                    experience similar to everyday text messaging.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Calendar' && (
                                            <Fragment>
                                                <p>
                                                    The calendar is extremely useful for hosts to
                                                    manage there boats, we have built a calendar
                                                    which a host can see bookings, blocked dates and
                                                    modified dates.
                                                    <br />
                                                    The host calendar has a special “Modify” button
                                                    that goes to a modify form where they can create
                                                    changes to there boat, specifically whether the
                                                    boat should become unavailable or available, and
                                                    if available, should the dates specified be
                                                    charged at the daily rate or a special higher or
                                                    lower rate. This feature allows hosts to now
                                                    manipulate prices of there boats within specific
                                                    seasons, as well as making there boats
                                                    unavailable, for instance, if there using the
                                                    boat that week or have an offline booking. You
                                                    can even click on the calendar itself to get a
                                                    start and end date, click the modify button and
                                                    automatically the dates are passed to the modify
                                                    page.s
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'App' && (
                                            <Fragment>
                                                <p>
                                                    We made an MVP mobile app which we’re planning
                                                    to expand on in the future, it is available for
                                                    both Android and iOS. The mobile app was a
                                                    prototype for an investor demonstration showing
                                                    how we can incorporate core functionalities from
                                                    the website into an easy-to-use mobile app.
                                                    <br />
                                                    The mobile app allows a user to search our
                                                    platform by list view or map view, and then
                                                    perform a booking on that given boat. In the
                                                    future, we hope to improve this greatly, to
                                                    incorporate our conversation system, as well as
                                                    our full booking system that works so well on
                                                    the desktop web app.
                                                </p>
                                            </Fragment>
                                        )}

                                        {selectedTab === 'Admin panel' && (
                                            <Fragment>
                                                <p>
                                                    The administration panel is specifically used
                                                    for staff of Skipper my boat. The panel allows
                                                    admins to log in and look at statistics of the
                                                    website, view users on the platform and
                                                    manipulate certain boat data.
                                                </p>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Grid>
            </div>
        </section>
    );
};

export default SiteLayoutOverview;
