import React from 'react';
import Img from 'gatsby-image';
import { SwiperSlide } from 'swiper/react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import CustomSwiper from '@components/CustomSwiper';
import ScrollTextWithImage from '@components/ScrollTextWithImage';
import Button from '@components/Button';
import { Link } from 'gatsby';

const Branding = ({ siteImage, sliderImages, brandingGraphic }) => {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingHeadingWrapper}>
                            <div className={styles.brandingHeading}>
                                <h2>
                                    Our detailed planning resulted in a polished website, mobile
                                    app, admin panel and API for both guests and hosts.
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={siteImage}>
                    <Divider xsMargin={5} mdMargin={0} />

                    <h2>Our Solution</h2>
                    <p>
                        After successfully launching an MVP, we took the time to split up the
                        project into 3 dedicated code bases. After building the multi-purpose API, a
                        great deal of time was spent focusing on UX and SEO to keep users engaged on
                        the platform. Building the main site in React meant we also could quickly
                        release mobile apps in React Native.
                        <br />
                        <br />
                        If you’re interested in us revitalising your website ready for the next
                        generation of web please read more.
                    </p>
                    <Link to="/service/web-platforms/web-development">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.brandingTextGraphicWrapper}>
                <Grid>
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <img src={brandingGraphic} alt="Skipper My Boat branding graphic" />
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <h2>Branding</h2>
                            <p>
                                Skipper my boat uses a strikingly pink accent across the brand which
                                revitalises the overall look and feel against a mellow blue which is
                                used as our secondary colour. The logo was formed as a ship’s
                                steering wheel to directly correlate to the usage of the platform is
                                to use these boats from consumer to host.
                            </p>
                        </Col>
                    </Row>
                </Grid>
            </div>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <div>
                            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.bottomHeadingWrapper}>
                            <div className={styles.bottomHeading}>
                                <h2>
                                    SkipperMyBoat now offers over 35,000 boats across the global in
                                    over 100 different countries.
                                </h2>
                                <Divider margin={3} />
                                <p>
                                    Our sites offer industry leading SSL and network security on top
                                    of our scalable infrastructure.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about <br />
                        Social Marketing?
                    </h2>
                }
                buttonText="Read more"
                link="/service/graphic-design/marketing"
            />
        </section>
    );
};

export default Branding;
