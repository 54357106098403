import React from 'react';
import Img from 'gatsby-image';
import { Col, Grid, Row } from '@components/Grid';
import styles from './styles.module.scss';

const Hero = ({ title, text, image, imageGatsby, backgroundColor, colorWhite }) => {
    return (
        <section className={styles.heroWrapper} style={{ background: backgroundColor }}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.heroContentWrapper}>
                            <div className={`${styles.heroContent} ${colorWhite ? styles.whiteText : ''}`}>
                                <h1>{title}</h1>
                                <p>{text}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>


            <div className={styles.heroImageWrapper}>
                {!!imageGatsby ? (
                    <Img fluid={imageGatsby} imgStyle={{ objectFit: 'cover' }} />
                ) : (
                    <Img src={image} alt='hero image'/>
                )}
            </div>
        </section>
    );
};

export default Hero;
