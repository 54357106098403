import React from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { graphql, Link } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/Seo';

import styles from './styles.module.scss';
import Hero from '@views/case-studies/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Branding from '@views/case-studies/skipper-my-boat/Branding';
import ReadMore from '@views/case-studies/ReadMore';
import SiteLayoutOverview from '@views/case-studies/skipper-my-boat/SiteLayoutOverview';

const SkipperMyBoat = ({ data }) => {
    return (
        <Layout>
            <SEO
                title=" Skipper My Boat • Case Studies"
                image={data?.gundiesPreview?.publicURL}
                description=" We not only helped Liveyou by redesigning their website but we equipped them with all the social marketing devices they need to drive accurately targeted market traffic to their website."
            />
            <div className={styles.caseStudyWrapper}>
                <Hero
                    title="Skipper My Boat"
                    text="Building and integrating a digital platform system to accommodate a global marketplace."
                    imageGatsby={data?.hero?.childImageSharp?.fluid}
                    backgroundColor="#21A0CC"
                    colorWhite
                />

                <ProjectOverview
                    client=" Skipper My Boat"
                    name="Global boat renting platform."
                    backgroundColor="#0066CC"
                    description={
                        <p>
                            Tasked to design, develop and support a scalable system that enables
                            painless boat rental bookings for hosts, guests and captains around the
                            world.
                        </p>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Description
                    image={data?.description?.childImageSharp?.fluid}
                    textLeft={
                        'Skipper My Boat is an online marketplace that connects people who want to rent boats to boat owners, charter operators and captains. Users can browse, compare and book boats. For boat owners, it’s the easiest way to monetise their expensive assets.'
                    }
                    textRight={
                        <div>
                            <p>
                                We worked with TravelCloud to build a solution that heavily focuses
                                on technology to change the fragmented $51 billion boat charter
                                industry and make rentals easy and online.
                            </p>
                            <p>
                                Users can browse and compare boats based on location, price, number
                                of people, type of activity they want to do and user experiences,
                                with a heavy focus on usability the platform also supports social
                                sign in, multiple payment options, wishlists, multi-user bookings
                                and more tools to bring the guests and hosts together.
                            </p>
                        </div>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Branding
                    siteImage={data?.brandingSiteImage?.childImageSharp?.fluid}
                    sliderImages={[
                        data?.brandingSliderImage01?.childImageSharp?.fluid,
                        data?.brandingSliderImage02?.childImageSharp?.fluid,
                        data?.brandingSliderImage03?.childImageSharp?.fluid,
                    ]}
                    brandingGraphic={data?.brandingGraphic?.publicURL}
                />

                <SiteLayoutOverview
                    siteScreenshot={data?.brandingGrid?.childImageSharp?.fluid}
                    iconHome={data?.iconHome?.publicURL}
                    iconSearch={data?.iconSearch?.publicURL}
                    iconBooking={data?.iconBooking?.publicURL}
                    iconEnquiry={data?.iconEnquiry?.publicURL}
                    iconOffer={data?.iconOffer?.publicURL}
                    iconBookRequest={data?.iconBookRequest?.publicURL}
                    iconConversation={data?.iconConversation?.publicURL}
                    iconCalendar={data?.iconCalendar?.publicURL}
                    iconApp={data?.iconApp?.publicURL}
                    iconAdminPanel={data?.iconAdminPanel?.publicURL}
                />

                <ReadMore />
            </div>
        </Layout>
    );
};

export default SkipperMyBoat;

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-smb-hero.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        description: file(absolutePath: { regex: "/case-studies-smb-description.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSiteImage: file(absolutePath: { regex: "/case-studies-smb-site-long.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingGraphic: file(absolutePath: { regex: "/case-studies-smb-branding.svg/" }) {
            publicURL
        }
        brandingSliderImage01: file(
            absolutePath: { regex: "/case-studies-smb-branding-slider-image-01.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSliderImage02: file(
            absolutePath: { regex: "/case-studies-smb-branding-slider-image-02.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSliderImage03: file(
            absolutePath: { regex: "/case-studies-smb-branding-slider-image-03.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentTop: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-top.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentMiddle: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-middle.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webDevelopmentBottom: file(
            absolutePath: { regex: "/case-studies-liveyou-web-development-bottom.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iconHome: file(absolutePath: { regex: "/es-web-homepage.svg/" }) {
            publicURL
        }
        iconSearch: file(absolutePath: { regex: "/es-web-search.svg/" }) {
            publicURL
        }
        iconBooking: file(absolutePath: { regex: "/es-web-booking.svg/" }) {
            publicURL
        }
        iconEnquiry: file(absolutePath: { regex: "/es-web-enquiry.svg/" }) {
            publicURL
        }
        iconOffer: file(absolutePath: { regex: "/es-web-special-offer.svg/" }) {
            publicURL
        }
        iconBookRequest: file(absolutePath: { regex: "/es-web-request-to-book.svg/" }) {
            publicURL
        }
        iconConversation: file(absolutePath: { regex: "/es-web-conversation.svg/" }) {
            publicURL
        }
        iconCalendar: file(absolutePath: { regex: "/es-calendar-booking.svg/" }) {
            publicURL
        }
        iconApp: file(absolutePath: { regex: "/es-app-booking.svg/" }) {
            publicURL
        }
        iconAdminPanel: file(absolutePath: { regex: "/es-web-admin-panel.svg/" }) {
            publicURL
        }
    }
`;
